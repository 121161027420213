import { Text } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { patienceDiff } from "../utils/patienceDiff";

export interface CheckTextFieldProps {
  yourAnswer: string;
  modelAnswer: string;
  setWrongWordCount: Function;
  index: number;
}

export const CheckTextField: React.FC<CheckTextFieldProps> = ({
  modelAnswer,
  yourAnswer,
  index: rowIndex,
  setWrongWordCount,
}) => {
  const result = useMemo(() => patienceDiff(modelAnswer, yourAnswer), [
    modelAnswer,
    yourAnswer,
  ]);
  useEffect(() => {
    setWrongWordCount((prev: any) => ({
      ...prev,
      [rowIndex]: result.lineCountDeleted,
    }));
  }, [
    modelAnswer,
    yourAnswer,
    setWrongWordCount,
    rowIndex,
    result.lineCountDeleted,
  ]);
  const renderText = () => {
    console.log("result", result);
    return result.lines.map((word: any, index) => {
      if (word.aIndex < 0) {
        return undefined;
      } else if (word.bIndex < 0) {
        return (
          <Text as="span" color="red" key={index}>
            {word.line}
          </Text>
        );
      } else {
        return (
          <Text as="span" color="green" key={index}>
            {word.line}
          </Text>
        );
      }
    });
  };
  return <>{renderText()}</>;
};
