import {
  Box,
  ChakraProvider,
  Flex,
  Heading,
  theme,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { InputStringPage } from "./pages/InputStringPage";
import { TypingPage } from "./pages/TypingPage";

export const App = () => {
  const [inputString, setInputString] = useState<string>("");
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <VStack spacing={4}>
          <Flex justify="space-between" width="100vw" align="center">
            <Heading as="h1" p={2} size="lg">
              ⌨️ 中文打字練習
            </Heading>
            <ColorModeSwitcher justifySelf="flex-end" />
          </Flex>
          {console.log("inputString", inputString)}
          {!inputString ? (
            <InputStringPage
              setInputString={setInputString}
              inputString={inputString}
            />
          ) : (
            <TypingPage
              inputString={inputString}
              setInputString={setInputString}
            />
          )}
        </VStack>
      </Box>
    </ChakraProvider>
  );
};
