import { Button, Heading, Textarea, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

export interface InputStringPageProps {
  inputString: string;
  setInputString: Function;
}

export const InputStringPage: React.FC<InputStringPageProps> = ({
  inputString,
  setInputString,
}) => {
  const [value, setValue] = useState<string>("");
  return (
    <VStack align="flex-start" spacing="2" width="80vw">
      <Heading as="h2">輸入文章</Heading>
      <Textarea
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
      <Button
        colorScheme="teal"
        isFullWidth
        onClick={() => setInputString(value)}
      >
        確認
      </Button>
    </VStack>
  );
};
