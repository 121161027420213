import { Container } from "@chakra-ui/layout";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { TypeField } from "../components/TypeField";
import { maxLength } from "../Constant";

export interface TypingPageProps {
  inputString: string;
  setInputString: Function;
}

export const TypingPage: React.FC<TypingPageProps> = ({
  inputString,
  setInputString,
}) => {
  const [wrongWordCount, setWrongWordCount] = useState<{
    [key: number]: number;
  }>({ 0: 0 });
  const [splitted, setSplitted] = useState<string[]>([]);
  useEffect(() => {
    const temp = [];
    const initWrongWordCount: typeof wrongWordCount = {};

    for (let i = 0; i < inputString.length; i = i + maxLength) {
      temp.push(inputString.substring(i, i + maxLength));
      initWrongWordCount[i / maxLength] = 0;
    }

    setSplitted(temp);
    setWrongWordCount(initWrongWordCount);
  }, [inputString]);

  // useEffect(() => {
  //   console.log("wrongWordCount", wrongWordCount);
  //   console.log("splitted", splitted);
  // });
  return (
    <Container maxWidth="500px">
      <VStack>
        <Button onClick={() => setInputString("")} isFullWidth>
          清除文章
        </Button>
        <HStack>
          <Text>
            錯字數:{" "}
            {Object.values(wrongWordCount).reduce((acc, value) => acc + value)}
          </Text>
          <Text>總字數: {inputString.length}</Text>
          <Text>
            合格率:{" "}
            {Math.round(
              ((inputString.length -
                Object.values(wrongWordCount).reduce(
                  (acc, value) => acc + value
                )) *
                100) /
                inputString.length
            )}
            %
          </Text>
        </HStack>

        {splitted.map((line, index) => (
          <TypeField
            text={line}
            index={index}
            setWrongWordCount={setWrongWordCount}
          />
        ))}
      </VStack>
    </Container>
  );
};
