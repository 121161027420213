import { VStack, Text, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import { maxLength } from "../Constant";
import { CheckTextField } from "./CheckTextField";

export interface TypeFieldProps {
  text: string;
  index: number;
  setWrongWordCount: Function;
}

export const TypeField: React.FC<TypeFieldProps> = ({
  text,
  index,
  setWrongWordCount,
}) => {
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState<string>("");
  return (
    <VStack align="flex-start">
      <Text fontSize="lg" textAlign="start" ml={4}>
        {!checked ? (
          text
        ) : (
          <CheckTextField
            modelAnswer={text}
            yourAnswer={value}
            setWrongWordCount={setWrongWordCount}
            index={index}
          />
        )}
      </Text>
      <Input
        name={`input-${index}`}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        maxlength={maxLength}
        fontSize="lg"
        width="400px"
        disabled={checked}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            setChecked(true);
            const nextSibling = document.querySelector(
              `input[name=input-${index + 1}]`
            ) as HTMLInputElement;
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          }
        }}
      ></Input>
    </VStack>
  );
};
